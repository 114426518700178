import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Subscribe } from "unstated";
import DataContainer from "../containers/DataContainer";
// import Loading from "./Loading";
import { FaRegClock, FaBars } from "react-icons/fa";
import AnswerModal from './AnswerModal';
import JudgeModal from './JudgeModal';
import { seconds2TimeStr } from '../functions/function'
import '../css/modal.css';
import { ANSWER_PHOTO_DIR } from "../config/endpoint";

const QuestionBoardContent = ({ globalState }) => {
  const {
    questions,
    questionFields,
    questionCases,
    learningRecords,
    isDataLoading
  } = globalState.state;

  const [searchParams] = useSearchParams();
  const questionCategoryId = searchParams.get("category");
  const questionYear = searchParams.get("year");

  const [questionGroupIndex, setQuestionGroupIndex] = useState(false);
  const [questionGroup, setQuestionGroup] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answerNo, setAnswerNo] = useState(0);
  const [judged, setJudged] = useState(false);
  const [correctQuestionIds, setCorrectQuestionIds] = useState([]);
  const [wrongQuestionIds, setWrongQuestionIds] = useState([]);
  const [wrongAnswers, setWrongAnswers] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);

  const [showModal2, setShowModal2] = useState(false);
  const [modalMessage2, setModalMessage2] = useState({ correct: 0, time: '' });
  const [showMenu, setShowMenu] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const loadFunc = () => {
    if (!sessionStorage.getItem("userId") || !sessionStorage.getItem("userPass")) {
      navigate("/login");
    }
    const learnerId = sessionStorage.getItem("learnerId");
    const params = { learner_id: learnerId, question_category_id: questionCategoryId, question_year: questionYear };
    globalState.getQuestions(params);
    setQuestionGroup(false);
    setQuestionIndex(0);
    stopTimer();
  }
  useEffect(() => {
    loadFunc();
    return () => {
      setQuestionGroup(false);
      setQuestionIndex(0);
      stopTimer();
    }
  }, []);

  const startTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setElapsedTime(prev => prev + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  const resetTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    setElapsedTime(0);
  };

  const handleLogOut = () => {
    sessionStorage.clear();
    navigate("/login");
  }
  const location = useLocation();
  const activeKey = location.pathname.split("/")[1];

  const handleChangeQuestionGroupIndex = (index) => {
    startTimer();
    setQuestionGroupIndex(index);
    const _group = [...questions].splice(index * 5, 5);
    setQuestionGroup(_group);
    setQuestionIndex(0);
    setCorrectQuestionIds([]);
    setWrongQuestionIds([]);
    setWrongAnswers({})
  }
  const handleSetAnswer = (answerNo) => {
    if (!judged) {
      setAnswerNo(answerNo);
    }
  }

  const handleJudged = () => {
    const judged = questionGroup[questionIndex].answer_choices[answerNo - 1].correct === 1;
    setJudged(true);
    // setModalMessage(judged ? "○正解" : "❌不正解");
    setModalMessage(judged);
    setShowModal(true);
    if (judged) {
      setCorrectQuestionIds([...correctQuestionIds, questionGroup[questionIndex].id])
    } else {
      setWrongQuestionIds([...wrongQuestionIds, questionGroup[questionIndex].id])
      setWrongAnswers({ ...wrongAnswers, [questionGroup[questionIndex].id]: answerNo })
    }

  }
  const handleNextQuestion = () => {
    setQuestionIndex(questionIndex + 1);
    setJudged(false);
    setAnswerNo(0);
  }

  const handleShowResult = async () => {
    //ここでタイマー終了
    stopTimer();
    //送信処理
    const learnerId = sessionStorage.getItem("learnerId");
    const params = {
      learner_id: learnerId,
      question_category_id: questionCategoryId,
      question_year: questionYear,
      question_group_index: questionGroupIndex,
      time_taken: elapsedTime,
      correct_count: correctQuestionIds.length,
      wrong_count: wrongQuestionIds.length,
      correct_question_ids: correctQuestionIds.join(","),
      wrong_question_ids: wrongQuestionIds.join(","),
      correct_count: correctQuestionIds.length
    };
    await globalState.createLearningRecord(params);

    // setModalMessage2(`正解数: ${correctQuestionIds.length}/5\n経過時間: ${seconds2TimeStr(elapsedTime)}`);
    setModalMessage2({ correct: correctQuestionIds.length, time: seconds2TimeStr(elapsedTime) });
    setShowModal2(true);
    setJudged(false)
    setQuestionGroupIndex(false);
    setQuestionGroup([])
    setAnswerNo(0)
    resetTimer()
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = (e) => {
    if (!e.target.closest('.accordion-menu') && !e.target.closest('.menu-button')) {
      setShowMenu(false);
    }
  };

  const handlePauseResume = () => {
    if (isPaused) {
      startTimer();
    } else {
      stopTimer();
    }
    setIsPaused(!isPaused);
  };

  useEffect(() => {
    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, []);

  useEffect(() => {
    if (isPaused && elapsedTime >= 300) { // 5分以上中断した場合
      resetTimer();
      navigate("/login");
    }
  }, [isPaused, elapsedTime, navigate]);

  useEffect(() => {
    loadFunc();
  }, []);
  const styles = {
    selectedAnswer: "bg-yellow-300",
    correctAnswer: "bg-blue-500",
    wrongAnswer: "bg-red-500",
    noStyle: ""
  }

  const getBadgeColor = (num) => {
    if (num < 4) return 'bg-red-500';
    return 'bg-blue-500';
  };

  const renderRubyText = (data) => {
    const dataArr = JSON.parse(data);
    return dataArr.map((item, index) => {
      if (item.r) {
        return (
          <ruby key={index}>
            {item.c}
            <rt>{item.r}</rt>
          </ruby>
        );
      } else {
        return <span key={index}>{item.c}</span>;
      }
    });
  };

  const questionGroupList = new Array(25).fill("").map(
    (v, i) => {
      const isBadge = learningRecords && learningRecords[questionYear] && learningRecords[questionYear][i] ? true : false;
      const ids = isBadge ? learningRecords[questionYear][i].correct_question_ids : "";
      const num = ids && ids !== "" ? ids.split(',').length : 0;
      return (
        <div key={`gl${i}`} onClick={() => handleChangeQuestionGroupIndex(i)}
          className="w-24 mr-2 mb-2 border border-gray-400 rounded-md text-center cursor-pointer relative">
          問{i * 5 + 1}-{(i + 1) * 5}
          {isBadge ?
            <div className={`absolute -top-2 -right-2 text-black rounded-full w-5 h-5 flex items-center text-white justify-center text-xs ${getBadgeColor(num)}`}>
              {num}
            </div> : <></>}
        </div>
      )
    }
  )

  const renderQuestionList = questionGroup.length && questionGroup.length !== 0 ? questionGroup.map(
    (question, qi) => {
      if (qi === questionIndex) {

        return (
          <div key={`q${question.id}`} className="mb-4">
            <div id="question_title" className="font-bold">{`問題${question.question_no}`}</div>
            {question.question_case_id !== 0 ?
              <div className="bg-green-100 p-4 rounded-lg text-left mx-4 my-4">
                <ruby>以下<rt>いか</rt></ruby>
                <span>の</span>
                <ruby>事例<rt>じれい</rt></ruby>
                <span>を</span>
                <ruby>読<rt>よ</rt></ruby>
                <span>んで、</span>
                <ruby>問<rt>と</rt></ruby>
                <span>いに</span>
                <ruby>答<rt>こた</rt></ruby>
                <span>えなさい</span><br /><br />
                {renderRubyText(questionCases[question.question_case_id].content)}
              </div> : <></>}
            <div className="bg-gray-100 p-4 rounded-lg text-left mx-4 my-4">
              {renderRubyText(question.rubi_content)}
            </div>
            <div className="flex items-center justify-center p-4 mx-4 my-4">
              {Number(question.is_image) !== 0 ? <img src={`${ANSWER_PHOTO_DIR}${question.id}.png`} alt="image_name" style={{ maxWidth: '300px' }} />
                : <></>}
            </div>
            <div id="choice_list">{
              question.answer_choices.map(
                (choice, i) => {
                  const answerStyle = !judged
                    ? (i + 1 === answerNo ? styles.selectedAnswer : styles.noStyle)
                    : (choice.correct === 1 ? styles.correctAnswer : (i + 1 === answerNo ? styles.wrongAnswer : styles.noStyle));
                  return (
                    <div className={`p-4 my-1 cursor-pointer border-2 rounded-lg ${answerStyle} mx-4 text-left break-words pl-8`}
                      key={`q${question.id}a${i}`}
                      onClick={() => handleSetAnswer(i + 1)}
                    >
                      {`${i + 1}. `}{renderRubyText(choice.rubi_content)}
                    </div>
                  )
                }
              )
            }
            </div>
          </div>
        )
      } else {
        return <></>
      }
    }
  ) : <></>

  return (
    <div className="flex flex-col min-h-screen">
      <nav className="bg-blue-100 p-4 flex justify-between items-center fixed top-0 w-full z-10">
        <div className="flex items-center">
          <img
            alt=""
            src="./AppIcon.png"
            className="inline-block"
            style={{ width: "30px", height: "30px", marginRight: "10px" }}
            onClick={() => navigate('/home')}
          />

        </div>
        <div className="flex items-center">
          <FaRegClock size={24} color="#3AABD2" />
          <span className="timer mr-10" style={{ color: "#3AABD2" }}>{`${seconds2TimeStr(elapsedTime)}`}</span>
          <button
            className="bg-transparent border-none p-0 m-0 cursor-pointer mr-4 menu-button"
            onClick={toggleMenu}
          >
            <FaBars size={24} color="#3AABD2" />
          </button>
          <div className={`accordion-menu ${showMenu ? 'show' : ''}`}>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => navigate('/home')}
            >
              ホーム
            </button>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => navigate('/learningRecord')}
            >
              学習記録
            </button>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => handleLogOut()}
            >
              ログアウト
            </button>
          </div>
        </div>
      </nav>
      <div className="flex-grow overflow-y-auto mt-24 mb-16">
        <div className="text-center">
          <h5 className="text-xl">
            <ruby>介護福祉士国家試験<rt>かいごふくししこっかしけん</rt></ruby>
          </h5>
          <h7 className="text-lg">{questionYear}<ruby>年<rt>ねん</rt></ruby></h7>
          <div className="flex flex-wrap justify-center mt-4">
            {!questionGroup.length ? questionGroupList : null}
          </div>
          {renderQuestionList}
        </div>
      </div>
      <div id="footer" className="bg-white p-4 fixed bottom-0 w-full flex justify-center">
        {questionGroup.length ?
          <button className="bg-blue-500 text-white px-4 py-2 rounded mx-2" onClick={() => handlePauseResume()}>
            {isPaused ? <ruby>再開<rt>けっか</rt></ruby> : <ruby>中断<rt>ちゅうだん</rt></ruby>}
          </button> : null}
        {judged ?
          (questionIndex < 4 ?
            <button className="bg-blue-500 text-white px-4 py-2 rounded mx-2" onClick={() => handleNextQuestion(questionIndex + 1)}>
              <ruby>次へ<rt>つぎ</rt></ruby></button> :
            <button className="bg-blue-500 text-white px-4 py-2 rounded mx-2" onClick={() => handleShowResult()}>
              <ruby>結果<rt>けっか</rt></ruby></button>) :
          (answerNo !== 0 ?
            <button className="bg-blue-500 text-white px-4 py-2 rounded mx-2" onClick={() => handleJudged()}><ruby>回答<rt>かいとう</rt></ruby></button> : null)}
      </div>
      <AnswerModal show={showModal} onClose={() => setShowModal(false)} message={modalMessage} />
      <JudgeModal show={showModal2} onClose={() => setShowModal2(false)} message={modalMessage2} />
    </div>
  )
}
const QuestionBoard = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <QuestionBoardContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default QuestionBoard;