import { Container } from "unstated";
import axios from "axios";
import { ENDPOINT } from '../config/endpoint';


export default class DataContainer extends Container {
  constructor(props = {}) {
    super();
    this.state = {
      isDataLoading: false,
      learnerId: "",
      learnerName: "",
      learningRecords: {},
      questions: [],
      questionFields: [],
      questionCases: [],
      todayRecords: {},
      weekRecords: {},
      monthRecords: {},
      totalRecords: {},
      allTodayRecords: {},
      allWeekRecords: {},
      allMonthRecords: {},
      allTotalRecords: {},
    };
  }

  getState() {
    return this.state;
  }
  async userLogin(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'userLogin', params)
      .then((rs) => {
        if (rs.data) {
          this.setState(
            {
              ...this.state,
              ...rs.data,
              isDataLoading: false
            }
          );
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getQuestions(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'questions', params)
      .then((rs) => {
        console.log(rs)
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async createLearningRecord(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'CreateLearningRecord', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getLearnerStatistics(params) {
    console.log(params)
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'learnerStatistics', params)
      .then((rs) => {
        console.log(rs)
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getAllLearningRecords(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'allLearningRecords', params)
      .then((rs) => {
        console.log(rs)
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }
}