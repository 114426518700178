import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import Home from './components/Home';
import Login from './components/Login';
import QuestionBoard from './components/QuestionBoard';
import LearningRecord from './components/LearningRecord';
import Dashboard from './components/Dashboard';
import { Provider } from "unstated";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider>
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/home' element={<Home />} />
        <Route path='/questionBoard' element={<QuestionBoard />} />
        <Route path='/learningRecord' element={<LearningRecord />} />
        <Route path='/dashboard' element={<Dashboard />} />
      </Routes>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

