import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Subscribe } from "unstated";
import { FaBars } from "react-icons/fa";
import DataContainer from "../containers/DataContainer";



const HomeContent = ({ globalState }) => {
  const {
    isDataLoading
  } = globalState.state;

  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const loadFunc = () => {
    if (!sessionStorage.getItem("userId") || !sessionStorage.getItem("userPass")) {
      navigate("/Login");
    }
  }
  useEffect(() => {
    loadFunc();
  }, []);

  const handleLogOut = () => {
    sessionStorage.clear();
    navigate("/Login");
  }
  const location = useLocation();
  const activeKey = location.pathname.split("/")[1];
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = (e) => {
    if (!e.target.closest('.accordion-menu') && !e.target.closest('.menu-button')) {
      setShowMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, []);
  return (
    <div className="container mx-auto">
      <nav className="bg-blue-100 p-4 flex justify-between items-center fixed top-0 w-full z-10">
        <div className="flex items-center">
          <img
            alt=""
            src="./AppIcon.png"
            className="inline-block"
            style={{ width: "30px", height: "30px", marginRight: "10px" }}
            onClick={() => navigate('/home')}
          />

        </div>
        <div className="flex items-center">
          <button
            className="bg-transparent border-none p-0 m-0 cursor-pointer mr-4 menu-button"
            onClick={toggleMenu}
          >
            <FaBars size={24} color="#3AABD2" />
          </button>
          <div className={`accordion-menu ${showMenu ? 'show' : ''}`}>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => navigate('/home')}
            >
              ホーム
            </button>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => navigate('/learningRecord')}
            >
              学習記録
            </button>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => handleLogOut()}
            >
              ログアウト
            </button>
          </div>
        </div>
      </nav>
      <div className="mt-12 flex justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mt-10">
            <ruby>
              学習
              <rt>がくしゅう</rt>
            </ruby>
            <span>メニュー</span>
          </h2>
          <h5 className="text-xl mt-4">
            <ruby>介護福祉士国家試験<rt>かいごふくししこっかしけん</rt></ruby>
          </h5>
          <div className="mt-4 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center items-center">
            <div className="cursor-pointer hover:text-blue-500" onClick={() => navigate('/questionBoard?category=1&year=2023')}>
              <span className="block text-center">
                <span>2023</span>
                <ruby>年<rt>ねん</rt></ruby>
                <span>　</span>
                <ruby>第<rt>だい</rt></ruby>
                <span>35</span>
                <ruby>回<rt>かい</rt></ruby>
              </span>
            </div>
            <div className="cursor-pointer hover:text-blue-500" onClick={() => navigate('/questionBoard?category=1&year=2022')}>
              <span className="block text-center">
                <span>2022</span>
                <ruby>年<rt>ねん</rt></ruby>
                <span>　</span>
                <ruby>第<rt>だい</rt></ruby>
                <span>34</span>
                <ruby>回<rt>かい</rt></ruby>
              </span>
            </div>
            <div className="cursor-pointer hover:text-blue-500" onClick={() => navigate('/questionBoard?category=1&year=2021')}>
              <span className="block text-center">
                <span>2021</span>
                <ruby>年<rt>ねん</rt></ruby>
                <span>　</span>
                <ruby>第<rt>だい</rt></ruby>
                <span>33</span>
                <ruby>回<rt>かい</rt></ruby>
              </span>
            </div>
            {/* <div className="cursor-pointer hover:text-blue-500">
              <span className="block text-center">2020年　第32回</span>
            </div>
            <div className="cursor-pointer hover:text-blue-500">
              <span className="block text-center">2019年　第31回</span>
            </div>
            <div className="cursor-pointer hover:text-blue-500">
              <span className="block text-center">2018年　第30回</span>
            </div>
            <div className="cursor-pointer hover:text-blue-500">
              <span className="block text-center">2017年　第29回</span>
            </div>
            <div className="cursor-pointer hover:text-blue-500">
              <span className="block text-center">2016年　第28回</span>
            </div>
            <div className="cursor-pointer hover:text-blue-500">
              <span className="block text-center">2015年　第27回</span>
            </div>
            <div className="cursor-pointer hover:text-blue-500">
              <span className="block text-center">2014年　第26回</span>
            </div>
            <div className="cursor-pointer hover:text-blue-500">
              <span className="block text-center">2013年　第25回</span>
            </div> */}
          </div>
        </div>
      </div>
      {/* <Loading loadingDis={isDataLoading ? "block" : "none"} /> */}
    </div>
  )
}
const Home = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <HomeContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Home;