import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Subscribe } from "unstated";
import { FaBars } from "react-icons/fa";
import DataContainer from "../containers/DataContainer";

import { seconds2TimeStr } from '../functions/function'


const LearningRecordContent = ({ globalState }) => {
  const {
    isDataLoading,
    todayRecords,
    weekRecords,
    monthRecords,
    totalRecords
  } = globalState.state;

  const [showMenu, setShowMenu] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const navigate = useNavigate();
  const loadFunc = () => {
    if (!sessionStorage.getItem("userId") || !sessionStorage.getItem("userPass")) {
      navigate("/Login");
    }
    const learnerId = sessionStorage.getItem("learnerId");
    const params = { learner_id: learnerId };
    globalState.getLearnerStatistics(params);
  }
  useEffect(() => {
    loadFunc();
  }, []);

  const handleLogOut = () => {
    sessionStorage.clear();
    navigate("/Login");
  }
  const location = useLocation();
  const activeKey = location.pathname.split("/")[1];

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = (e) => {
    if (!e.target.closest('.accordion-menu') && !e.target.closest('.menu-button')) {
      setShowMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, []);

  const records = {
    today: todayRecords,
    week: weekRecords,
    month: monthRecords,
    total: totalRecords
  };
  return (
    <div className="container mx-auto">
      <nav className="bg-blue-100 p-4 flex justify-between items-center fixed top-0 w-full z-10">
        <div className="flex items-center">
          <img
            alt=""
            src="./AppIcon.png"
            className="inline-block"
            style={{ width: "30px", height: "30px", marginRight: "10px" }}
            onClick={() => navigate('/home')}
          />

        </div>
        <div className="flex items-center">
          <button
            className="bg-transparent border-none p-0 m-0 cursor-pointer mr-4 menu-button"
            onClick={toggleMenu}
          >
            <FaBars size={24} color="#3AABD2" />
          </button>
          <div className={`accordion-menu ${showMenu ? 'show' : ''}`}>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => navigate('/home')}
            >
              ホーム
            </button>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => navigate('/learningRecord')}
            >
              学習記録
            </button>
            <button
              className="block w-full text-left px-4 py-2"
              onClick={() => handleLogOut()}
            >
              ログアウト
            </button>
          </div>
        </div>
      </nav>
      <div className="mt-12 flex justify-center">
        <div className="text-center">
          <h4 className="mt-10 mb-4">{sessionStorage.getItem('userName')}</h4>
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className={`px-4 py-2 ${selectedPeriod === 'today' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => setSelectedPeriod('today')}
            >
              今日
            </button>
            <button
              className={`px-4 py-2 ${selectedPeriod === 'week' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => setSelectedPeriod('week')}
            >
              最近1週間
            </button>
            <button
              className={`px-4 py-2 ${selectedPeriod === 'month' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => setSelectedPeriod('month')}
            >
              今月
            </button>
            <button
              className={`px-4 py-2 ${selectedPeriod === 'total' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
              onClick={() => setSelectedPeriod('total')}
            >
              累計
            </button>
          </div>
          <div class="overflow-x-auto px-1">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr>
                  <th className="px-1 py-2 border-b-2 border-gray-300 bg-gray-200 text-left"></th>
                  <th className="px-1 py-2 border-b-2 border-gray-300 bg-gray-200 text-center">
                    {selectedPeriod === "today" && "今日"}
                    {selectedPeriod === "week" && "最近1週間"}
                    {selectedPeriod === "month" && "今月"}
                    {selectedPeriod === "total" && "累計"}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2 border-b border-gray-300">時間</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-center">{seconds2TimeStr(records[selectedPeriod].total_time_taken, true)}</td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border-b border-gray-300">回答</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-center">
                    {Number(records[selectedPeriod].total_correct_count) + Number(records[selectedPeriod].total_wrong_count)}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 border-b border-gray-300">正答</td>
                  <td className="px-4 py-2 border-b border-gray-300 text-center">{Number(records[selectedPeriod].total_correct_count)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <Loading loadingDis={isDataLoading ? "block" : "none"} /> */}
    </div>
  )
}
const LearningRecord = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <LearningRecordContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default LearningRecord;