export const seconds2TimeStr = (seconds,hour=false) => {
    let _s = seconds % 60;
    _s = _s < 10 ? `0${_s}` : _s;

    let _m = (seconds - _s) / 60;
    _m = _m % 3600;
    _m = _m < 10 ? `0${_m}` : _m;
    const _h = Math.floor(seconds / 3600);
    if(hour){
        return `${_h}:${_m}:${_s}`;
    }
    return `${_m}:${_s}`;
}