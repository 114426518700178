import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";

import DataContainer from "../containers/DataContainer";
import packageJson from "../../package.json";


const LoginContent = ({ navigation, globalState }) => {
  const [userId, setUserId] = useState('');
  const [userPass, setUserPass] = useState('');
  const navigate = useNavigate();
  const userLogin = () => {
    const params = { userId: userId, userPass: userPass };
    globalState.userLogin(params).then(rs => {
      if (rs) {
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('userPass', userPass);
        sessionStorage.setItem('userName', rs.userName);
        sessionStorage.setItem('learnerId', rs.learnerId);
        console.log(rs)
        if (Number(rs.admin) === 1) {
          navigate('/dashboard');
        } else {
          navigate('/home');
        }

      } else {
        alert('ログインできませんでした。');
      }
    })
  }


  return (
    <div className="container mx-auto">
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center">
          <div className="mb-4">
            介護ラーニングサイト<br />Care Learning
          </div>
          <img
            alt=""
            src="./AppIcon.png"
            className="mx-auto"
            style={{ width: "80px", height: "80px" }}
          />
          <div className="mt-4">
            {packageJson.version}
          </div>
          <form className="mt-4">
            <div className="mb-4">
              <label className="block text-left">User ID</label>
              <input type="text" placeholder="App ID" className="block w-full px-3 py-2 border rounded-md" value={userId} onChange={(e) => setUserId(e.target.value)} />
            </div>
            <div className="mb-4">
              <label className="block text-left">パスワード</label>
              <input type="password" placeholder="パスワード" className="block w-full px-3 py-2 border rounded-md" value={userPass} onChange={(e) => setUserPass(e.target.value)} />
            </div>
            <div className="mb-4">
              <input type="checkbox" className="mr-2" />
              <label>パスワードを保存</label>
            </div>
            <button type="button" className="px-4 py-2 bg-blue-500 text-white rounded-md" onClick={() => userLogin()}>
              ログイン
            </button>
          </form>
        </div>
      </div>
    </div>

    // <Container fluid >
    //   <Row>
    //     <Col className="d-flex align-items-center" style={{ minHeight: "300px" }} md={12}>
    //       <div className="d-block mx-auto">
    //         <div style={{ marginBottom: "10px", width: "100%", textAlign: "center" }}>介護ラーニングサイト<br />CareLearning</div>
    //         <img
    //           alt=""
    //           src="./AppIcon.png"
    //           width="80"
    //           height="80"
    //           className="d-block mx-auto"
    //         />

    //         <div style={{ marginTop: "20px", width: "100%", textAlign: "center" }}>{packageJson.version}</div>
    //         <Form >
    //           <Form.Group className="mb-3">
    //             <Form.Label>User ID</Form.Label>
    //             <Form.Control type="text" placeholder="App ID" value={userId} onChange={(e) => setUserId(e.target.value)} />
    //           </Form.Group>
    //           <Form.Group className="mb-3" >
    //             <Form.Label>パスワード</Form.Label>
    //             <Form.Control type="password" placeholder="パスワード" value={userPass} onChange={(e) => setUserPass(e.target.value)} />
    //           </Form.Group>
    //           <Form.Group className="mb-3">
    //             <Form.Check type="checkbox" label="パスワードを保存" />
    //           </Form.Group>
    //           <Button variant="primary" type="button" onClick={() => userLogin()}>
    //             ログイン
    //           </Button>
    //         </Form>
    //       </div>
    //     </Col>
    //   </Row>
    // </Container>
  )
}
const Login = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <LoginContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Login;