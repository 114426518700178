import React, { useState, useEffect } from 'react';
import '../css/modal.css';

const JudgeModal = ({ show, onClose, message }) => {
    return (
        <div className={`modal ${show ? 'show' : ''}`}>
            <div className="modal-content">
                <div>
                    <ruby>正解数：<rt>せいかいすう</rt></ruby><span>{message.correct || ""}</span>
                </div>
                <div>
                    <ruby>経過時間：<rt>けいかじかん</rt></ruby><span>{message.time || ""}</span>
                </div>



                <div className="flex justify-center mt-4">
                    <button className="close-button mt-4 bg-blue-500" onClick={onClose}>
                        <ruby>閉<rt>と</rt></ruby><span>じる</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default JudgeModal;