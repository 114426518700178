import React, { useState, useEffect } from 'react';
import '../css/modal.css';

const AnswerModal = ({ show, onClose, message }) => {
    return (
        <div className={`modal ${show ? 'show' : ''}`}>
            <div className="modal-content">
                {message ? <><span>⭕️ </span><ruby>正 解<rt>せいかい</rt></ruby></> :
                    <><span>❌ </span><ruby>不正解<rt>ふせいかい</rt></ruby></>}
                <div className="flex justify-center mt-4">
                    <button className="close-button mt-4 bg-blue-500" onClick={onClose}>
                        <ruby>閉<rt>と</rt></ruby><span>じる</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AnswerModal;